import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

// Connects to data-controller="sort"
export default class extends Controller {
  connect() {
    // console.log('sort connected')
    this.sortable = Sortable.create(
      this.element, {
        onEnd: this.end.bind(this)
      }
    )
  }

  end(event) {
    // console.log('sort, end', event)
    let data = new FormData()
    data.append('id', event.item.dataset.id)
    data.append('position', event.newIndex + 1)
    Rails.ajax({
      url: event.item.dataset.url,
      type: "PATCH",
      data: data,
    })
  }
}
